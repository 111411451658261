@import "../UI.module";

$m: 0.5rem;

.time {
  display: inline-block;

  border-radius: $borderRadius;
  text-align: center;
  font-weight: bold;
  cursor: pointer;

  @include outlined($textColor);

  &.active {
    @include outlined($primaryColor);
    color: $primaryColor;
  }

  &.disabled {
    @include disabled();
  }

  &:active {
    @include pressed();
  }

  padding: 5px;
  margin: 0.25rem $m;
}

.times {
  user-select: none;

  height: 100%;
  padding: 0.5rem 0;
  padding-left: 0.5rem;
  border-left: 1px solid $textColorLight;

  h4 {
    font-size: 0.7 * $fontSize;
    text-align: left;
    color: $textColorLight;
    margin: 0;
    margin-bottom: 5px;
  }

  .time {
    width: calc(50% - #{$m} * 2);
    @media (max-width: 575px) {
      width: calc(100% - #{$m} * 2);
    }
  }
}
