@import "../UI/UI.module";

.nav {
  padding: 1rem 0;
  margin: 0;

  .item {
    transition: 0.3s all;
    cursor: pointer;
    text-align: center;

    &:hover {
      background-color: $textColorLight;
    }

    @include outlined(transparent);

    &.active {
      background-color: $textColorLight;
      @include outlined($primaryColor);
    }
  }
}
