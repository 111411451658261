@import "../UI/UI.module";

.appointmentSearch {
  .form {
    position: relative;

    border-radius: $borderRadius;

    background-color: whitesmoke;
    padding: 1rem 2rem;

    hr {
      margin: 1rem 0.3rem;
      opacity: 0.5;
    }

    .deleteIcon {
      position: absolute;
      right: 3rem;
      top: 2rem;
      @media (max-width: 768px) {
        position: relative;
        right: 1rem;
        top: unset;
      }
      @include iconButton();
    }

    .dateGroup {
      top: -50%;
      position: relative;
    }

    .formControl {
      @media (max-width: 992px) {
        margin-top: 1rem;
      }
    }

    .formControl:not(.date):not(.radio) {
      width: 100%;
      max-width: 100%;
    }

    button[type="submit"],
    .radio {
      margin: 5px;
    }

    .radio {
      height: unset !important;
    }

    button[type="submit"] {
      // a bugfix
      margin-bottom: 0;
    }

    .formWarning {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 14px;

      padding: 1rem 0;

      * > div {
        display: flex;
      }

      h3 {
        font-weight: bold;
        margin: 0;
      }

      p {
        margin: 10px 0 0 0;
      }

      .icon {
        background: red;
        color: white;
        border-radius: $borderRadius;
        width: 35px;
        height: 35px;

        font-size: 20px;
        line-height: 35px;
        text-align: center;
      }
    }
  }

  .results {
    padding-top: 1rem;
    position: relative;
    border-radius: $borderRadius;
    top: -1rem;
    background-color: $backgroundColor;
    max-width: 100%;

    .header {
      padding: 1rem 0 1.5rem 0;
      align-items: center;
      user-select: none;

      h2 {
        text-align: center;
        margin: 0;

        span {
          color: $textColorLight;
        }

        i {
          padding: 0 2rem;
          @include iconButton();
        }
      }

      @media (max-width: 992px) {
        h2 {
          text-align: left;
          font-size: $fontSize * 1.3;
        }
        span {
          @include iconButton();
        }
      }

      @media (max-width: 575px) {
        h2 {
          text-align: left;
          font-size: $fontSize;

          i {
            padding: 0 1rem;
          }
        }
      }

      button {
        i {
          margin-left: 1rem;
        }
      }
    }

    .nextAvailable {
      color: $dangerColor;
      font-weight: bold;
      font-size: 1.2rem;
      border-top: 1px solid $textColorLight;

      i {
        margin-right: 1rem;
      }
    }

    .item {
      &:nth-child(1) {
        border-top: 1px solid $textColorLight;
      }

      border-bottom: 1px solid $textColorLight;

      padding: 1rem 0;

      .doctor {
        img {
          border-radius: $borderRadius;

          //height: 100%;
          width: 100%;
          object-fit: cover;
        }

        h2 {
          text-align: left;
        }

        i {
          width: 1.5rem;
        }
      }

      .showMore {
        text-align: center;
        margin-top: 1rem;

        a {
          cursor: pointer;
        }
      }

      .timeOptions {
      }
    }
  }
}
