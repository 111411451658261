@import "../UI.module";

$iconWidth: 3rem;

.navbar {
  text-align: center;
  position: relative;
  border-bottom: 1px solid $textColorLight;
  margin-bottom: 1rem;

  h1 {
    padding: 0 $iconWidth;

    @media (max-width: 350px) {
      font-size: 1.5rem;
    }
  }

  .icon {
    padding: 1rem;
    width: $iconWidth;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: whitesmoke;
    border-radius: $borderRadius;

    @include iconButton();
  }
}
